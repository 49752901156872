import { useEffect, useState } from 'react'

declare global {
  interface Window {
    matchMedia: (query: string) => MediaQueryList
  }
}

const useIsDesktop = (): boolean => {
  const matchMedia = global.window?.matchMedia('(min-width: 992px)')
  const [isDesktop, setIsDesktop] = useState(matchMedia?.matches || false)

  const handleResize = e => {
    setIsDesktop(e.matches || false)
  }

  useEffect(() => {
    if (matchMedia?.addEventListener) matchMedia.addEventListener('change', handleResize)

    return () => {
      if (matchMedia?.removeEventListener) matchMedia.removeEventListener('change', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isDesktop
}
export default useIsDesktop
