import { createContext, Dispatch, SetStateAction } from 'react'
import Product from 'types/Product'
import ProductImage from 'types/ProductImage'
import Asset from 'types/Asset'

interface ProductContext {
  permalink: string
  product: Product
  previewedProduct: Product
  album: Asset[] | ProductImage[]
  groupedProducts: Product[]
  selectedSize: string
  previewedSize: string
  error: string
  selectedModelAlbum: string
  collection: any
  isGiftcard: boolean
  openAddToCartModal: () => void
  closeAddToCartModal: () => void
  setError: (string) => void
  setPreviewedProduct: Dispatch<SetStateAction<Product>>
  setPreviewedSize: Dispatch<SetStateAction<string>>
  setSelectedModelAlbum: Dispatch<SetStateAction<string>>
  setSelectedSize: Dispatch<SetStateAction<string>>
}

const Context = createContext<Partial<ProductContext>>({})

export default Context
