/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState, useContext, useEffect, useMemo } from 'react'
import Cookie from 'js-cookie'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import useScrollPosition from 'lib/hooks/useScrollPosition'
import useGeolocation from 'lib/network/hooks/useGeolocation'
import useSession from 'lib/network/hooks/useSession'
import useCart from 'lib/network/hooks/useCart'
import useEngagement from 'lib/network/hooks/useEngagement'
import useAppConfig from 'lib/network/hooks/useAppConfig'
import useWarehouseInventoryCounts from 'lib/network/hooks/useWarehouseInventoryCounts'
import useIsMobile from 'lib/hooks/useIsMobile'
import { useIsLargeTablet } from 'lib/hooks/useIsTablet'
import usePrevious from 'lib/hooks/usePrevious'

import importEverlaneIcon, { Icon } from 'lib/iconProps'
import { getMenuByName, recursiveMenuSearch } from 'lib/helpers/navigationV2'
import { enablePageScroll, disablePageScroll, isPageScrollLocked } from 'lib/helpers/scroll_helpers'
import { buildNotifications } from 'lib/helpers/notifications'
import isAdminUser from 'lib/helpers/adminUsers'
import setupEventTracking, { publishEvent, clearEventTracking } from 'lib/events/tracking'
import { getItemCount } from 'lib/helpers/cart/line_items'

import NavigationContext from 'contexts/NavigationContext'
import AuthModal from 'components/auth/AuthModal'
import { ModalStates } from 'components/auth/AuthModal/CopyTypes'
import { Media } from 'components/core/BreakpointMedia'

import Cookies from 'types/Cookies'
import Event from 'types/Event'
import MenuNotification from 'types/MenuNotification'
import { trackBuilderClicks } from './menuHelper'

import EverlaneLogo from './EverlaneLogo'
import DesktopMenu from './DesktopMenu'
import CartIcon from './CartIcon'

import styles from './styles.module.scss'
import { ACTIVE_GENDER_MENU } from './Constants'

const X = importEverlaneIcon(Icon.X)
const MenuIcon = importEverlaneIcon(Icon.MENU)
const SearchIcon = importEverlaneIcon(Icon.SEARCH)

const AccountDropdown = dynamic(() => import('./DesktopMenu/AccountDropdown'), { ssr: false })
const MobileMenu = dynamic(() => import('./MobileMenu'), { ssr: false })
const RegionDropdown = dynamic(() => import('./RegionDropdown'), { ssr: false })

export const NAV_BAR_HEIGHT = 40
export const navbarId = 'navbar'

declare global {
  interface Window {
    HAS_LOGGED_ENGAGEMENT: boolean
    LUX: any
  }
}

const Search = () => {
  const router = useRouter()

  return (
    <Link
      href="/search"
      shallow
      passHref
      className={classNames(styles['navbar__link-wrapper'], styles['navbar__link-wrapper--search'])}
      onClick={() => {
        publishEvent(Event.AlgoliaSearch.NAV_CLICK, {
          from: router.asPath,
        })

        if (router.asPath.includes('search')) {
          global.window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }
      }}
      role="button"
      tabIndex={0}
      aria-label="Search"
    >
      <Media lessThan="large">
        <SearchIcon />
      </Media>
      <Media greaterThanOrEqual="large">
        <span className={styles.navbar__link}>Search</span>
      </Media>
    </Link>
  )
}

const Cart = () => {
  const { data: cart } = useCart()
  const itemCount = getItemCount(cart?.lineItems)

  return (
    <div className={styles.navbar__link} aria-label={`${itemCount} items in your bag`}>
      <Media lessThan="large">
        <CartIcon height="16px" width="16px" />
      </Media>
      <Media greaterThanOrEqual="large">
        <>{itemCount > 0 ? `Bag (${itemCount})` : 'Bag'}</>
      </Media>
    </div>
  )
}

const Navbar = ({ triggerAuthModal, showMenuIcon, showCartIcon }) => {
  const isMobile = useIsMobile()
  const isTablet = useIsLargeTablet()

  const isDesktop = !isMobile && !isTablet

  const router = useRouter()
  const isCheckoutPath = router.asPath.includes('checkout')

  const { data: appConfig } = useAppConfig()
  const { data: session } = useSession()
  const { data: geolocation } = useGeolocation()
  const { data: inventoryCounts } = useWarehouseInventoryCounts({ skip: !isAdminUser(session) })

  const queryString = router.asPath.split('?')[1]
  const { mutate: logEngagement } = useEngagement({
    initialParams: {
      landingPath: router.asPath,
      queryString: queryString ? `?${queryString}` : '',
    },
  })

  const {
    collection,
    product,
    isNavigationMenuOpen,
    setIsNavigationMenuOpen,
    menus,
    isRegionDropdownOpen,
    setIsRegionDropdownOpen,
    isBannerSticky,
    openAddToCartModal,
    closeSubMenu,
  } = useContext(NavigationContext)
  const fakedActiveMenu = {
    text: '',
    permalink: '',
    placement: '',
    platform: 'desktop-mobile',
    categories: [],
  }

  const [bannerId, setBannerId] = useState(null)
  const [isNewUser, setIsNewUser] = useState(null)
  const [isSubNavHidden, setIsSubNavHidden] = useState(false)
  const [bannerHeight, setBannerHeight] = useState(48)
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false)
  const [isAccountDropdownOpened, setIsAccountDropdownOpened] = useState(false)
  const [isRegionDropdownRendered, setIsRegionDropdownRendered] = useState<boolean>(false)
  const [activeMenu, setActiveMenu] = useState(fakedActiveMenu)
  const [topHeight, setTopHeight] = useState(NAV_BAR_HEIGHT)
  const [shouldRenderSubNav, setShouldRenderSubNav] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [prevClientScrollSt, setPrevClientScrollSt] = useState(0)

  const mobileMenus = menus?.filter(menu => menu.platform !== 'desktop')
  const desktopMenus = menus?.filter(menu => menu.platform !== 'mobile')

  const { user, visitor } = session ?? {}
  const { id: userId = null, gender: userGender = 'female' } = user ?? {}
  const {
    segment = null,
    has10PercentOff = false,
    hasWelcomeOffer = false,
    hasReferralCoupon = false,
    gender: visitorGender = null,
    email: visitorEmail = null,
    sessionId = null,
  } = visitor ?? {}

  const previouslySelectedMenu = usePrevious(activeMenu) || fakedActiveMenu
  const currentlySelectedMenu = useMemo(() => {
    if (!isMobile) {
      return recursiveMenuSearch(desktopMenus, router.asPath, userGender) || {}
    }
    return {}
  }, [desktopMenus, userGender, router.asPath, isMobile])

  const notifications = buildNotifications({
    user: session?.user || {},
    has10PercentOff,
    hasWelcomeOffer,
    hasReferralCoupon,
  }) as MenuNotification[]

  useEffect(() => {
    if (session && global.window && !global.window.HAS_LOGGED_ENGAGEMENT) {
      logEngagement()
      global.window.HAS_LOGGED_ENGAGEMENT = true
    }
  }, [logEngagement, session])

  useEffect(() => {
    if (isRegionDropdownOpen && !isRegionDropdownRendered) {
      setIsRegionDropdownRendered(true)
    }
  }, [isRegionDropdownOpen, isRegionDropdownRendered])

  useEffect(() => {
    async function initializeTrackers() {
      const initialize = (await import('lib/trackers/initialize')).default
      initialize({ session, appConfig })
    }
    if (global.window && appConfig && session) {
      initializeTrackers()
    }
  }, [appConfig, session])

  useEffect(() => {
    async function initializeAdminBar() {
      const initialize = (await import('lib/utils/adminBar')).default
      initialize(session, inventoryCounts, collection, product)
    }
    if (global.document && isAdminUser(session)) {
      initializeAdminBar()
    }
  }, [session, inventoryCounts, collection, product])

  useEffect(() => {
    if (isDesktop) {
      enablePageScroll()
    }
  }, [isDesktop])

  useEffect(() => {
    setupEventTracking({ sessionId, userId, isMobile })

    return clearEventTracking
  }, [sessionId, userId, isMobile])

  useEffect(() => {
    if (
      isDesktop &&
      currentlySelectedMenu?.text !== previouslySelectedMenu.text &&
      Object.keys(currentlySelectedMenu).length > 0 &&
      router.asPath !== '/search'
    ) {
      setActiveMenu(currentlySelectedMenu)
      global.window.sessionStorage.setItem(ACTIVE_GENDER_MENU, currentlySelectedMenu?.text)
    } else if (isDesktop && router.asPath === '/search' && menus) {
      const menu = getMenuByName(menus, global.window.sessionStorage.getItem(ACTIVE_GENDER_MENU))
      if (menu) setActiveMenu(menu)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedMenu, previouslySelectedMenu, setActiveMenu, isDesktop])

  useEffect(() => {
    async function fetchBannerId() {
      const { bannerId: bid } = await import('components/Layout/RotatingBanner')
      return bid
    }

    async function setUserAttributes() {
      const { setUserActivationStatus } = await import('lib/trackers/google_tag_manager')
      setUserActivationStatus(segment ?? 'unactivated')

      const bid = await fetchBannerId()
      setBannerId(bid)
    }

    if (geolocation && session) setUserAttributes()
  }, [geolocation, segment, session, isMobile, visitorGender])

  const banner = useMemo(() => {
    if (!bannerId) return null

    return document.getElementById(bannerId)
  }, [bannerId])

  useEffect(() => {
    if (!banner) return

    setBannerHeight(banner.offsetHeight)
  }, [banner])

  // Sticky navbar
  useScrollPosition(
    ({ currPos, prevPos }) => {
      const clientScrollY = currPos.y * -1
      const prevClientScrollY = prevPos.y * -1
      setPrevClientScrollSt(prevClientScrollY)
      if (isCheckoutPath) setIsSubNavHidden(true)
      else if (!isDesktop) setIsSubNavHidden(true)
      else if (isHovering) setIsSubNavHidden(false)
      else {
        setPrevClientScrollSt(prevClientScrollY)
        if (clientScrollY === prevClientScrollSt) return
        if (clientScrollY < prevClientScrollY) setIsSubNavHidden(false)
        else setIsSubNavHidden(clientScrollY > 40 && !isHovering)
      }
    },
    // the prevClientScrollSt a dependency that will reduce flashing as it resets the values used inside the hook
    [prevClientScrollSt],
    null,
    null,
    100,
  )

  useEffect(() => {
    if (isRegionDropdownOpen) {
      publishEvent(Event.Header.COUNTRY_TRAY_OPEN)
    }
  }, [isRegionDropdownOpen])

  useEffect(() => {
    if (!isRegionDropdownOpen && !isNavigationMenuOpen && isPageScrollLocked()) {
      enablePageScroll()
    }
  }, [isRegionDropdownOpen, isNavigationMenuOpen])

  useEffect(() => {
    if (triggerAuthModal) {
      setIsAuthModalOpen(true)
    }
  }, [triggerAuthModal])

  let modalState: string
  if (isNewUser === true) {
    modalState = ModalStates.SIGN_UP
  } else if (isNewUser === false) {
    modalState = ModalStates.LOG_IN
  }

  useEffect(() => {
    const height = NAV_BAR_HEIGHT + bannerHeight - 16
    setTopHeight(height)
  }, [bannerHeight])

  useEffect(() => {
    if (isHovering) setIsSubNavHidden(false)
    else {
      setShouldRenderSubNav(
        isDesktop &&
          showMenuIcon &&
          !isSubNavHidden &&
          !isCheckoutPath &&
          !!desktopMenus &&
          (activeMenu.text === '' ||
            activeMenu.text === undefined ||
            activeMenu.categories?.length > 0),
      )
    }
  }, [
    activeMenu,
    desktopMenus,
    isHovering,
    isSubNavHidden,
    isCheckoutPath,
    isDesktop,
    showMenuIcon,
  ])

  // Make sure we hide the desktop subnavigation on window resize
  useEffect(() => {
    if (isMobile || isTablet || isCheckoutPath) setIsSubNavHidden(true)
    else if (!isSubNavHidden) setIsSubNavHidden(!isDesktop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  return (
    <nav
      id={navbarId}
      className={classNames(styles['navbar-container'], {
        [styles['navbar-container--subnav-hidden']]: isSubNavHidden,
        [styles['navbar-container--minimal']]:
          (!isMobile && !showMenuIcon && !showCartIcon) || !shouldRenderSubNav,
        [styles['navbar-container--sticky-banner']]: isBannerSticky,
      })}
      onMouseLeave={() => {
        setIsAccountDropdownOpened(false)
        setIsHovering(false)
      }}
      onMouseEnter={() => {
        if (!isCheckoutPath) {
          setIsSubNavHidden(false)
          setIsHovering(true)
        }
      }}
    >
      <div
        className={classNames(styles.navbar__wrapper, {
          [styles['navbar__wrapper--minimal']]: !showMenuIcon && !showCartIcon,
        })}
      >
        <div className={styles['navbar__wrapper-container']}>
          <div className={styles['navbar__menu-left']}>
            {showMenuIcon && mobileMenus && (
              <Media lessThan="largeTablet">
                <div className={styles['navbar__icon-wrapper']}>
                  <button
                    type="button"
                    className={styles['navbar__icon-button']}
                    aria-expanded={isNavigationMenuOpen}
                    onClick={() => {
                      if (!isNavigationMenuOpen) {
                        publishEvent(Event.Navigation.OPEN)
                        setIsNavigationMenuOpen(true)
                        disablePageScroll()
                      } else {
                        publishEvent(Event.Navigation.CLOSE)
                        setIsNavigationMenuOpen(false)
                        enablePageScroll()
                      }
                    }}
                  >
                    {isNavigationMenuOpen ? (
                      <X height="20px" width="20px" />
                    ) : (
                      <MenuIcon height="20px" width="20px" />
                    )}
                  </button>
                </div>
              </Media>
            )}
            {showMenuIcon && (
              <Media greaterThanOrEqual="largeTablet">
                <ul className={styles.navbar__categories}>
                  {desktopMenus?.map(category => (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                      key={category.text}
                      className={classNames(styles.navbar__category, {
                        [styles['navbar__category--active']]:
                          activeMenu && activeMenu?.text === category?.text,
                      })}
                      onClick={() => {
                        trackBuilderClicks(category)
                        router.push(category.url)
                      }}
                    >
                      <Link
                        href={category.url}
                        passHref
                        className={styles['navbar__category-link']}
                      >
                        {category.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Media>
            )}
          </div>
          <div
            className={classNames(styles['navbar__menu-center'], {
              [styles['navbar__menu-center--minimal']]: !isMobile && !showMenuIcon && !showCartIcon,
            })}
          >
            <Link
              href="/"
              passHref
              className={classNames(
                styles.navbar__link,
                styles['navbar__link--logo'],
                styles['navbar__link-wrapper'],
                styles['navbar__link-wrapper--logo'],
              )}
              onClick={() => {
                const pageType = router.asPath.split('/')[1]

                if (isMobile) closeSubMenu()

                publishEvent(Event.Header.LOGO_CLICK, {
                  page_type: pageType,
                })
              }}
              role="button"
              tabIndex={0}
              aria-label="Go to Everlane home"
            >
              <EverlaneLogo height="14px" />
            </Link>
          </div>

          {showCartIcon && desktopMenus?.length > 0 && (
            <div className={styles['navbar__menu-right']}>
              <Search />

              {showMenuIcon && (
                <Media greaterThanOrEqual="largeTablet">
                  <div
                    className={classNames(
                      styles['navbar__link-wrapper'],
                      styles['navbar__link-wrapper--account'],
                    )}
                  >
                    <div
                      className={styles.navbar__link}
                      onClick={() => setIsAccountDropdownOpened(!isAccountDropdownOpened)}
                      role="button"
                      tabIndex={0}
                      aria-label="Open My Account dropdown"
                    >
                      Account
                    </div>

                    {desktopMenus && isAccountDropdownOpened && (
                      <AccountDropdown
                        notifications={notifications}
                        setIsAccountDropdownOpened={setIsAccountDropdownOpened}
                        openAuthModal={({ isNewUser: isSigningUp }) => {
                          setIsAuthModalOpen(true)
                          setIsNewUser(isSigningUp)
                        }}
                      />
                    )}
                  </div>
                </Media>
              )}

              <div
                className={classNames(
                  styles['navbar__link-wrapper'],
                  styles['navbar__link-wrapper--cart'],
                )}
                onClick={() => {
                  publishEvent(Event.Navigation.CART_DROP_DOWN_CLICK)
                  openAddToCartModal()
                }}
                role="button"
                tabIndex={0}
                aria-label="Open cart"
              >
                <Cart />
              </div>
            </div>
          )}
        </div>
      </div>

      {menus.length > 0 && mobileMenus && !isDesktop && (
        <MobileMenu
          isNavigationMenuOpen={isNavigationMenuOpen}
          closeModal={() => setIsNavigationMenuOpen(false)}
          openAuthModal={({ isNewUser: isSigningUp }) => {
            setIsAuthModalOpen(true)
            setIsNewUser(isSigningUp)
          }}
          openRegionDropdown={() => setIsRegionDropdownOpen(true)}
          topPosition={topHeight}
          notifications={notifications}
        />
      )}

      <DesktopMenu
        menu={activeMenu}
        hidden={isSubNavHidden && !shouldRenderSubNav}
        setIsHovering={setIsHovering}
      />

      <AuthModal
        isOpen={isAuthModalOpen && !!appConfig}
        closeModal={() => {
          setIsAuthModalOpen(false)
          Cookie.set(Cookies.DISMISSED_LOGIN_GATE, Date.now())
        }}
        visitorEmail={visitorEmail}
        modalState={modalState}
      />
      {(isRegionDropdownOpen || isRegionDropdownRendered) && (
        <RegionDropdown
          isOpen={isRegionDropdownOpen}
          closeRegionDropdown={() => setIsRegionDropdownOpen(false)}
        />
      )}
    </nav>
  )
}

export default Navbar
